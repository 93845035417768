.intro {
  margin-top: 15vh;
  background-image: url('../../icons/canopy2.svg');
  background-size: min(45vw, 200px);
  background-repeat: no-repeat;
  background-position: top right;
}

.intro h1 {
  color: #ddffff;
  font-size: 2.4rem;
  margin-bottom: 0.3rem;
}

.intro h2 {
  margin-bottom: 2rem;
}

.essentials {
  margin-top: 1rem;
  margin-left: 0.2rem;
}

.essentials a {
  width: fit-content;
  display: flex;
  align-items: center;
  color: #ddffff;
  border-radius: 20px;
  padding: 5px 10px;
  background: #7cccf544;
  margin-bottom: 1.5rem;
}

.essentials span {
  color: #ddffff;
  font-size: 1.15rem;
  padding-left: 10px;
  white-space: nowrap;
}

a:hover {
  background-color: #7cccf566;
}

.essentials a:last-child {
  margin-bottom: 0;
}

@media (min-width: 740px) {
  .intro {
    margin-top: 10vh;
    background-image: url('../../icons/canopy4.svg');
    background-size: min(33vw, 500px);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 60vh;
  }

  .intro h1 {
    visibility: hidden;
  }

  .intro h2 {
    font-size: 2rem;
    margin-top: -2rem;
  }

  .intro h3 {
    font-size: 1.5rem;
  }

  .essentials span {
    font-size: 1.4rem;
  }
}

/* SVG TREE SCALING FOR LARGER VIEWS */
@media (min-width: 1400px) {
  .intro {
    background-position: 64vw top;
  }
}

@media (min-width: 1500px) {
  .intro {
    background-position: 63vw top;
  }
}

@media (min-width: 1600px) {
  .intro {
    background-position: 61vw top;
  }
}

@media (min-width: 2000px) {
  .intro {
    background-position: 60vw top;
  }
}

.timeline {
  background-image: url('../../icons/plaintrunk.svg');
  background-size: min(45vw, 200px);
  background-repeat: repeat-y;
  background-position: top right;
}

.links {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.timeline p {
  font-size: 0.9rem;
}

.timeline a {
  font-size: 1rem;
  padding: 5px 10px;
  border-radius: 10px;
  background-color: #2f80ed33;
  margin-top: 10px;
  text-align: right;
  width: initial;
}

.timeLineTrunk {
  padding: 2em 0 0 0;
}

.timeLineTrunk::before {
  background: none;
}

.timeline h2 {
  padding-top: 2.5rem;
  margin-bottom: -1rem;
}

.timeLineNode {
  border: 7px solid black;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.timeLineBox {
  color: #ddffff;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.timeLineBox p {
  color: black;
  text-align: justify;
  width: 100%;
}

.timeLineDate {
  opacity: 1;
}

@media (min-width: 740px) {
  .timeline {
    background-image: url('../../icons/plaintrunk2.svg');
    background-size: min(33vw, 500px);
  }

  .desktopFlexWrapper {
    display: flex;
    justify-content: center;
  }

  .desktopFlexItem {
    width: 60%;
    margin-left: 10%;
    max-width: 800px;
    font-size: 1.15rem;
  }

  .timeLineBox {
    padding-right: 14%;
  }
}

/* SVG TREE SCALING FOR LARGER VIEWS */
@media (min-width: 1400px) {
  .timeline {
    background-position: 64vw;
  }
}

@media (min-width: 1500px) {
  .timeline {
    background-position: 63vw;
  }
}

@media (min-width: 1600px) {
  .timeline {
    background-position: 61vw;
  }
}

@media (min-width: 2000px) {
  .timeline {
    background-position: 60vw;
  }
}

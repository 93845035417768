.nav {
  height: 8vh;
  min-height: 75px;
  width: 100%;
  padding-right: 10px;
  margin-left: -10px;
  position: fixed;
  top: 0;
  color: #ddffff;
  display: grid;
  grid-template-areas: 'left center right';
  grid-template-columns: 5fr 3fr 1fr;
  grid-template-rows: 1fr;
  align-items: center;
  background: #56ccf2;
  background: -webkit-linear-gradient(to left, #2f80ed, #56ccf2);
  background: linear-gradient(to right, #2f80ed, 60%, #56ccf2);
  z-index: 1000;
}

.scrollNav {
  border-bottom: 3px solid black;
}

.initialControls {
  width: 50vw;
  /* margin-left: auto; */
  grid-area: center;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.scrolledControls {
  animation: moveControls 1s forwards;
}

@keyframes moveControls {
  0% {
    opacity: 0;
    grid-area: left;
    justify-self: start;
  }
  20% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translateX(10px);
    transform: scale(0.8);
  }
}

.hiddenTitle {
  display: none;
  opacity: 0;
  grid-area: left;
}

.notHiddenTitle {
  animation: revealTitle 1.25s forwards;
}

@keyframes revealTitle {
  0% {
    display: block;
    opacity: 0;
    grid-area: center;
    width: 50vw;
    font-size: 1.7rem;
  }
  10% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    grid-area: center;
    width: 50vw;
    font-size: 1.6rem;
    transform: translate(15px, 3px);
  }
}

.nav span {
  font-family: 'Ubuntu', sans-serif;
  font-size: 1.75rem;
  color: #ddffff;
}

.title {
  color: #ddffff;
  font-size: 2rem;
  margin-bottom: 2vh;
}

.lang {
  margin-right: 2vw;
}

.toggle {
  margin-bottom: -8px;
}

.burger {
  grid-area: right;
  justify-self: end;
}

@media (min-width: 740px) {
  .nav {
    display: flex;
    justify-content: center;
    text-align: center;
    padding-right: 0;
  }

  .scrollNav {
    display: flex;
    justify-content: center;
    text-align: center;
  }

  .initialControls {
    order: 1;
    width: 100%;
  }

  .scrolledControls {
    animation: default;
    order: 1;
    width: 100%;
  }

  .lang {
    width: 100%;
  }

  .hiddenTitle {
    display: block;
    opacity: 1;
    order: 2;
    width: 100%;
    font-size: 2.2rem;
  }

  .notHiddenTitle {
    animation: default;
    display: block;
    opacity: 1;
    order: 2;
    width: 100%;
    font-size: 2.2rem;
  }

  .burger {
    order: 3;
    text-align: center;
    width: 100%;
  }
}

@media (min-width: 810px) {
  .hiddenTitle {
    font-size: 2.5rem;
  }

  .notHiddenTitle {
    font-size: 2.5rem;
  }
}

/* @media (min-width: 740px) {
  .initialControls {
    margin-left: -9%;
  }

  @keyframes revealTitle {
    0% {
      display: default;
      opacity: 0;
      grid-area: center;
      width: 50vw;
      font-size: 1.7rem;
    }
    10% {
      opacity: 0;
    }
    100% {
      opacity: 1;
      grid-area: center;
      justify-self: center;
      width: 50vw;
      font-size: 2.4rem;
      transform: translate(-15px, 3px);
    }
  }
}

@media (min-width: 1600px) {
  .initialControls {
    margin-left: -4%;
  }

  @keyframes revealTitle {
    0% {
      display: default;
      opacity: 0;
      grid-area: center;
      width: 50vw;
      font-size: 1.7rem;
    }
    10% {
      opacity: 0;
    }
    100% {
      opacity: 1;
      grid-area: center;
      justify-self: center;
      width: 50vw;
      font-size: 2.4rem;
      padding-left: 2vw;
    }
  }
} */

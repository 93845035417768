/* CLEARFIX & GLOBAL STYLES */

* {
  margin: 0;
  box-sizing: border-box;
}

img {
  max-width: 100%;
  display: block;
}

body {
  min-width: 350px;
  /* max-width: 1920px; */
  padding-left: 10px;
  background: #56ccf2; /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #2f80ed, #56ccf2); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #2f80ed,
    60%,
    #56ccf2
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

h1 {
  font-family: 'Ubuntu', sans-serif;
  font-size: 2rem;
}

h2 {
  font-family: 'Ubuntu', sans-serif;
  font-size: 1.75rem;
  font-weight: lighter;
  margin-bottom: 0.5rem;
}

h3 {
  font-family: 'Ubuntu', sans-serif;
  font-size: 1.2rem;
  font-weight: lighter;
  margin-bottom: 0.5rem;
}

h4 {
  font-family: 'Ubuntu', sans-serif;
  font-weight: lighter;
}

p,
a,
li,
span {
  font-family: 'Oxygen', sans-serif;
  line-height: 1.5rem;
  width: 80%;
}

p {
  margin-top: 1rem;
}

a {
  color: #ddffff;
}

.footer {
  margin-left: -10px;
  border-top: 3px solid black;
  padding-left: 10px;
  padding-top: 2rem;
  padding-bottom: 1rem;
  color: #ddffff;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footerItem {
  width: 60%;
  margin-left: 10%;
  max-width: 800px;
}

.firstI {
  align-self: flex-start;
}

.secondI {
  align-self: center;
}

.thirdI {
  align-self: flex-end;
  padding-right: 10px;
}

.fourthI {
  padding-top: 10px;
  align-self: center;
}

.email a {
  width: fit-content;
  display: flex;
  align-items: center;
  color: black;
  border-radius: 20px;
  padding: 5px 10px;
  background: #7cccf544;
  margin-bottom: 1.5rem;
}

.email span {
  color: black;
  font-size: 1.15rem;
  padding-left: 10px;
  white-space: nowrap;
}

a:hover {
  background-color: #7cccf566;
}

@media (min-width: 740px) {
  .firstI {
    align-self: center;
    padding-right: 11%;
  }

  .secondI {
    align-self: center;
  }

  .thirdI {
    align-self: center;
    padding-left: 13%;
  }

  .fourthI {
    padding-top: 10px;
    align-self: center;
  }
}

.topSection {
  background-image: url('../../icons/plaintrunk.svg');
  background-size: min(45vw, 200px);
  background-repeat: repeat-y;
  background-position: top right;
}

.topSection h2 {
  padding-top: 2.5rem;
}

.topSection p {
  text-align: justify;
}

.highlight {
  color: #ddffff;
}

@media (min-width: 740px) {
  .topSection {
    background-image: url('../../icons/plaintrunk2.svg');
    background-size: min(33vw, 500px);
  }

  .desktopFlexWrapper {
    display: flex;
    justify-content: center;
  }

  .desktopFlexItem {
    width: 60%;
    margin-left: 10%;
    max-width: 800px;
    font-size: 1.15rem;
  }
}

/* SVG TREE SCALING FOR LARGER VIEWS */
@media (min-width: 1400px) {
  .topSection {
    background-position: 64vw;
  }
}

@media (min-width: 1500px) {
  .topSection {
    background-position: 63vw;
  }
}

@media (min-width: 1600px) {
  .topSection {
    background-position: 61vw;
  }
}

@media (min-width: 2000px) {
  .topSection {
    background-position: 60vw;
  }
}
